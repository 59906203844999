require('./bootstrap');

$( document ).ready(function() {

    const hideLink = $('#hide-link');
    const showLink = $('#show-link');
    const buttonsDiv = $('#app-button-block');

    hideLink.click(function (e) {
        toggle(e);
        buttonsDiv.find('a').each(
            function (index, elem) {
                if (index !== 0) {
                    elem.classList.add('not-displayed');
                }
            }
        );
    });

    showLink.click(function (e) {
        toggle(e);
        buttonsDiv.find('a').each(
            function (index, elem) {
                elem.classList.remove('not-displayed');
            }
        );
    });

    function toggle (e) {
        e.preventDefault();
        hideLink.toggle();
        showLink.toggle();
    }
});
